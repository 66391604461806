<template>
    <div class="about-container" id="about">
        <div class="about-content">
            <v-row justify="space-between">
                <v-col lg="12" sm="12" cols="12" md="5" class="" id="about-us-content">
                    <div class="about-col">
                        <h3 class="header title" id="about-us">About Us</h3>
                        <p class="description" id="desc">Signa X is powering the next frontier through interoperable digital twin creation and tokenization. Signa X is one of the fast growing ventures and is supported by some of the leading VC's and has been featured in global media publications.</p>
                        <!-- <p class="description" id="desc">Signa X is the 3D asset creation platform for the Metaverse. We optimise the entire 3D asset production process for enterprises, industries and gaming projects to build and deploy metaverse experiences at scale.<br /><br />Our proprietary tech stack is ensuring digital 3D assets are created and tokenized at one of the fastest rates in the world.</p> -->
                        <!-- <div class="about-stats" id="desktop">
                            <v-col lg="3" class="stats-one">
                                <div class="stats">
                                    <h3 class="header">350+</h3>
                                    <p class="description">Creators</p>
                                </div>
                            </v-col>
                            <v-col lg="3" class="stats-two">
                                <div class="stats">
                                    <h3 class="header">15+</h3>
                                    <p class="description">Countries</p>
                                </div>
                            </v-col>
                            <v-col lg="3" class="stats-three">
                                <div class="stats">
                                    <h3 class="header step-three" >10+</h3>
                                    <p class="description">Partnerships</p>
                                </div>
                            </v-col>
                            <v-col lg="3" class="stats-three">
                                <div class="stats">
                                    <h3 class="header step-three" >3+ Million</h3>
                                    <p class="description">User Coverage</p>
                                </div>
                            </v-col>
                        </div>
                        <div class="about-stats mt-5" id="mobile">
                            <v-row>
                                <v-col lg="3" sm="3" md="3" class="stats-one">
                                <div class="stats">
                                    <h3 class="header">350+</h3>
                                    <p class="description">Creators</p>
                                </div>
                            </v-col>
                            <v-col lg="3" sm="3" md="3"  class="stats-two">
                                <div class="stats">
                                    <h3 class="header">15+</h3>
                                    <p class="description">Countries</p>
                                </div>
                            </v-col>
                            <v-col  lg="3" class="stats-three">
                                <div class="stats">
                                    <h3 class="header step-three" >10+</h3>
                                    <p class="description">Partnerships</p>
                                </div>
                            </v-col>
                            <v-col lg="3" sm="3" md="3"  class="stats-three">
                                <div class="stats">
                                    <h3 class="header step-three" >3+ Mil</h3>
                                    <p class="description">Users</p>
                                </div>
                            </v-col>
                            </v-row>
                        </div> -->
                    </div>
                </v-col>
                
            </v-row>
        </div>
    </div>
</template>
<script>
import 'animate.css';

export default {
    name: "About",
    computed: {
        isMobileView: function () {
            return window.innerWidth <= 760 ? true : false;
        },
    },
}
</script>
<style scoped>
#mobile{
    display: none;
}
.about-container {
    width: 100%;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    max-width: 100%;
}

.about-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-col {
    width: 90%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
    margin-left: 5%;
}

.title {
    font-size: 70px !important;
    text-align: left;
    font-family: 'Orbitron', sans-serif !important;
}

.description {
    width: 85%;
  margin: 10px 0px 10px 0px;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  color: #e0e0e0;
  font-size: 26px;
  font-weight: 500;
}

.content-image {
    width: 100%;
    height: 100%;
}

.content-image>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header {
    background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Orbitron', sans-serif !important;
    font-weight: 800;
}

.about-stats-base {
    width: 100%;
    height: 15vh;
    border-radius: 10px;
    /* background-image: url(../assets/about-stats-frame.svg); */
    background-position: 100% 100%;
    background-size: cover;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.about-stats {
    width: 100%;
    height: 15vh;
    border-radius: 10px;
    /* background-image: url(../assets/about-stats-frame.svg); */
    background-position: 100% 100%;
    background-size: cover;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* margin-left: 50px; */
}

.about-stats-two {
    width: 100%;
    height: 20vh;
    border-radius: 10px;
    /* background-image: url(../assets/about-stats-frame.svg); */
    background-position: 100% 100%;
    background-size: cover;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.stats-new {
    width: 90%;
    height: 15vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url(../assets/about-stats-frame.svg); */
    background-position: 100% 100%;
    background-size: cover;
    border-radius: 10px;
}

.stats>h3 {
    font-size: 20px;
    width: 100%;
}

.stats>p {
    font-size: 25px !important;
}

.divider {
    width: 2px;
    height: 13vh;
    background-color: white;
}

.stats-one {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}

.stats-three {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 10px;
}

.stats-two {
    padding: 0px;
    margin: 0px;
}
.step-three{
    width: 70%;
}
@media only screen and (max-width: 600px) {
    .about-container {
        width: 100%;
        height: auto;
        overflow: hidden;
        padding-top: 10%
    }

    .image {
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    .about-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-col {
        width: 95%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 10px 20px 10px 0px;
    }

    .title {
        font-size: 30px !important;
        text-align: center;
        font-family: 'Orbitron', sans-serif !important;
        font-weight: 800;
    }

    .description {
        font-size: 18px;
        text-align: justify;
        font-weight: 400;
        font-family: 'Chakra Petch', sans-serif;
        color: white;
    }

    #desc{
        margin: 15% 0px;
    }

    .content-image {
        width: 100%;
        height: 100%;
    }

    .content-image>img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .header {
        background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: 'Orbitron', sans-serif !important;
    }

    .about-stats {
        width: 100%;
        height: 20vh;
        border-radius: 10px;
        /* background-image: url(../assets/about-stats-frame.svg);
        background-position: 100% 100%;
        background-size: cover; */
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .stats>h3 {
        font-size: 20px;
        text-align: center;
    }
    .stats > p {
        font-size: 16px !important;
        text-align: center;
    }
    .stats>.description {
        font-size: 16px;
    }

    .divider {
        width: 2px;
        height: 15vh;
        background-color: white;
    }

    .stats-one {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
    }

    .stats-two {
        padding: 0px;
        margin: 0px 0px 0px 0px;
    }

    .stats-three {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
    }
    .step-three{
        width: 100%;
    }
    #desktop{
        display: none;
    }
    #mobile{
        display: block;
    }
}

@media only screen and (min-width: 768px) {
    .about-container {
        width: 100%;
        height: 60vh;
        overflow: hidden;
        padding-top: 0%;
    }
}
</style>